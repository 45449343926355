import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState, AppDispatch } from ".";
import firebase, { auth } from "../firebase";
import { getCollection, getSingletonDocument } from "../firebase/firestoreUtils";
import { MANAGE_USERS } from "../permissionConstants";
import { executeAnalyticsQuery } from "../firebase/functions";
import type { DoseDeviceAlarmSettings, UserSettings } from "../firebase/models";

function getInitialState(): UserDataState {
  return {
    user: null,
    permissions: [],
    role: null,
    userSettings: null,
    initialized: false,
    units: [],
    chartsData: null,
    doseDeviceAlarmSettings: [],
  };
}

export const userDataSlice = createSlice({
  name: "userData",
  initialState: getInitialState(),
  reducers: {
    userAuthenticated: (state, action) => {
      state.user = action.payload;
    },
    permissionsLoaded: (state, action) => {
      state.permissions = action.payload;
    },
    roleLoaded: (state, action) => {
      state.role = action.payload;
    },
    userSettingsLoaded: (state, action) => {
      state.userSettings = action.payload;
    },
    userInitialized: (state, action) => {
      state.initialized = action.payload;
    },
    unitsLoaded: (state, action) => {
      state.units = action.payload;
    },
    doseDeviceAlarmSettingsLoaded: (state, action) => {
      state.doseDeviceAlarmSettings = action.payload;
    },
    chartsDataLoaded: (state, action) => {
      state.chartsData = action.payload ?? [];
    },
  },
  extraReducers(builder) {
    builder.addCase(logout.fulfilled, () => {
      return getInitialState();
    });
  },
});

export const logout = createAsyncThunk("userData/logout", async () => {
  await auth.signOut();
});

export const loginWithEmail = createAsyncThunk("userData/loginWithEmail", async (request: { email: string; password: string }) => {
  const { email, password } = request;
  await auth.signInWithEmailAndPassword(email, password);
});

export const loginWithGoogle = createAsyncThunk("userData/loginWithGoogle", async () => {
  await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
});

export const loadRolesAndPermissions = createAsyncThunk<void, string, { dispatch: AppDispatch }>("userData/loadRolesAndPermissions", async (userId: string, { dispatch }) => {
  const doc = await getCollection("user_roles").doc(userId).get();
  const roleValue = doc?.data()?.Role;
  if (!roleValue) {
    return;
  }

  dispatch(roleLoaded(roleValue));
  const permissionsDoc = await getCollection("roles_permissions").doc(roleValue).get();
  const permissions = permissionsDoc?.data();
  if (permissions && permissions?.Permissions != null) {
    const permissionIds = permissions.Permissions.map((item: { id: string }) => item.id);
    dispatch(permissionsLoaded(permissionIds));
  }
});

export const loadUserSettings = createAsyncThunk("userData/loadUserSettings", async (userId: string, { dispatch }) => {
  const userSettingsDocs = await getCollection("user_settings").where("UserId", "==", userId).get();
  if (userSettingsDocs.docs.length > 0) {
    const userSettings = userSettingsDocs.docs[0].data();
    dispatch(
      userSettingsLoaded({
        Id: userSettings.id,
        FirstName: userSettings.FirstName,
        LastName: userSettings.LastName,
      })
    );
  }
});

export const loadUnits = createAsyncThunk("userData/units", async (_, { dispatch }) => {
  const units = await getCollection("medication_units").get();
  const data = units.docs.map((x) => {
    return { Id: x.id, ...x.data() };
  });
  dispatch(unitsLoaded(data));
});

export const loadDoseDeviceAlarmSettings = createAsyncThunk("userData/doseDeviceSettings", async (_, { dispatch }) => {
  const settingsList = await getSingletonDocument("dose_device_settings_default").collection("alarm_settings").get();
  const data = settingsList.docs.map((x) => {
    return { Id: x.id, ...x.data() };
  });
  dispatch(doseDeviceAlarmSettingsLoaded(data));
});

export const loadChartsData = createAsyncThunk("userData/chartsData", async (_, { dispatch }) => {
  const result = await executeAnalyticsQuery();
  dispatch(chartsDataLoaded(result));
});

export const getUser = (state: RootState) => state.userData.user;
export const getRole = (state: RootState) => state.userData.role;
export const getPermissions = (state: RootState) => state.userData.permissions as Array<string>;
export const getIsUserDataInitialized = (state: RootState) => state.userData.initialized;
export const getUserSettings = (state: RootState) => state.userData.userSettings;
export const getCanManageUsers = (state: RootState) => getPermissions(state).indexOf(MANAGE_USERS) != -1;
export const getUnits = (state: RootState) => state.userData.units;
export const getDoseDeviceAlarmSettings = (state: RootState) => state.userData.doseDeviceAlarmSettings;
export const getChartsData = (state: RootState) => state.userData.chartsData;

export default userDataSlice.reducer;

export const { userAuthenticated, permissionsLoaded, roleLoaded, userSettingsLoaded, userInitialized, unitsLoaded, doseDeviceAlarmSettingsLoaded, chartsDataLoaded } = userDataSlice.actions;
export type UserDataState = {
  user: firebase.User | null;
  permissions: Array<string>;
  role: string | null;
  userSettings: UserSettings | null;
  initialized: boolean;
  units: any[];
  chartsData: any[] | null;
  doseDeviceAlarmSettings: DoseDeviceAlarmSettings[];
};
