import { functions } from "../firebase";
import GatsbyConfig from "../../gatsby-config";

export async function createCustomGroup(name: string, description: string, algoliaKey: string): Promise<null> {
  return await call("createCustomGroup", { name, description, algoliaKey });
}

export async function changeCustomGroup(userId: string, userObjectId: string, customGroupId: string, operation: string): Promise<null> {
  return await call("changeCustomGroup", { userId, userObjectId, customGroupId, operation });
}

export async function executeAnalyticsQuery(): Promise<object> {
  return await call("executeAnalyticsQuery");
}

export async function requestAutomaticFeedback(userId: string): Promise<null> {
  return await call("requestAutomaticFeedback", { userId });
}

export async function sendPushNotifications(title: string, body: string, userIds: string[], openPage: string): Promise<null> {
  return await call("sendPushNotifications", { title, body, userIds, openPage });
}

async function call<Result>(name: string, data: object = {}): Promise<Result> {
  try {
    const json = JSON.stringify({ configuration: GatsbyConfig.siteMetadata.firestore_configuration, ...data });
    const callable = functions.httpsCallable(getFunctionName(name));
    return (await callable({ input: json })).data as Result;
  } catch (e: any) {
    console.error(`Error executing dashboard function ${name}, error: ${e}`, e);
    throw e;
  }
}

function getFunctionName(name: string): string {
  return `dashboard-${name}`;
}
