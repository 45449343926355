import "./src/styles/global.css";
import wrapWithProvider from "./wrap-with-provider";
import { auth } from "./src/firebase";
import { userAuthenticated } from "./src/store/userDataSlice";
import store from "./src/store";

export const wrapRootElement = wrapWithProvider;

export function onInitialClientRender() {
  // ReactDOM.render
  auth.onAuthStateChanged(async (user) => {
    store.dispatch(userAuthenticated(user ? { Id: user.uid, DisplayName: user.displayName } : null));
  });
}
