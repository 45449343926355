/* eslint-disable @typescript-eslint/no-non-null-assertion */
import firebase from "firebase";
import { firestore } from "./index";
import gatsbyConfig from "../../gatsby-config";
import { Alarm, Application, Chat, ChatDashboardData, ChatMessage, CustomGroup, DoseDeviceEvent, User, FirebaseUser } from "./models";
import userDefaultIcon from "../images/user_default.svg";
import { localizeDateUTCString } from "../utils/dateUtils";

export function getMultiUserDocuments(collectionName: string, schemaVersion: number, userId: string): firebase.firestore.Query {
  const collection = firestore.collection(getCollectionName(collectionName));
  if (schemaVersion < 8) {
    return collection.where("UserId", "==", userId);
  }
  return collection.where(`ReaderUserIds`, "array-contains", userId);
}

export function getOwnedMultiUserDocuments(collectionName: string, schemaVersion: number, userId: string): firebase.firestore.Query {
  const collection = firestore.collection(getCollectionName(collectionName));
  if (schemaVersion < 8) {
    return collection.where("UserId", "==", userId);
  }
  return collection.where(`WriterUserIds`, "array-contains", userId);
}

export function getCollectionName(name: string): string {
  return `configurations/${gatsbyConfig.siteMetadata!.firestore_configuration}/${name}`;
}

export function getSingletonDocumentPath(documentId: string): string {
  return `configurations/${gatsbyConfig.siteMetadata!.firestore_configuration}/singletons/${documentId}`;
}

export function getCollection(collectionName: string): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
  return firestore.collection(getCollectionName(collectionName));
}

export function getSingletonDocument(documentId: string): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
  return firestore.doc(getSingletonDocumentPath(documentId));
}

export function toAlarmModel(document: firebase.firestore.DocumentSnapshot): Alarm {
  const data = document.data()!;
  return {
    Id: document.id,
    MedicationName: data.MedicationName,
    ReceiverName: data.ReceiverName,
    Reaction: data.Reaction ?? { Type: data.ReactionType, DateTime: data.ReactionDateTime },
    DateTime: data.DateTime.toMillis(),
    ReaderUserIds: data.ReaderUserIds,
    WriterUserIds: data.WriterUserIds,
    Users: data.Users,
    MedicationTimeZone: data.MedicationTimeZone,
    Quantity: data.Quantity,
    AsNeededMedication: data.AsNeededMedication,
  };
}

export function toChats(documentSnapshot: firebase.firestore.DocumentSnapshot): Array<Chat> {
  const data = documentSnapshot.data();
  if (data?.Chats == null) {
    return [];
  }

  const { Chats } = data;
  const toChat = (document: any) => {
    const userName = !!document.UserName ? document.UserName : "Anonymous";
    return { Id: document.Id, UserId: document.UserId, UserName: userName, LastMessageTimestamp: document.LastMessageTimestamp?.toMillis() } as Chat;
  };
  return Object.keys(Chats).map((chatId) => toChat(Chats[chatId]));
}

export function toChatDashboardDataModel(document: firebase.firestore.DocumentSnapshot): ChatDashboardData {
  const { LastReadMessages, FollowingChats } = document.data() as { LastReadMessages: Array<{ LastReadMessageTimestamp: firebase.firestore.Timestamp | null; ChatId: string }>; FollowingChats: Array<string> | null };
  const messages = LastReadMessages.map((lastReadMessage) => {
    return { ...lastReadMessage, LastReadMessageTimestamp: lastReadMessage.LastReadMessageTimestamp?.toMillis() ?? 0 };
  });
  return { Id: document.id, LastReadMessages: messages, FollowingChats: FollowingChats };
}

export function toChatMessageModel(document: firebase.firestore.DocumentSnapshot): ChatMessage {
  const data = document.data()!;
  const userName = !!data.UserFirstName ? data.UserFirstName : "Anonymous";
  return {
    Id: document.id,
    UserId: data.UserId,
    Message: data.Message,
    UserFirstName: userName,
    UserAvatarUrl: data.UserAvatarUrl,
    CreatedAt: data.CreatedAt.toMillis(),
    // for some reason it comes with null value sometimes
    SavedAt: data.SavedAt?.toMillis() ?? data.CreatedAt.toMillis(),
    Images: data.Images,
    URL: data.URL,
  };
}

export function toApplicationModel(data: firebase.firestore.DocumentData, smartDeviceData: firebase.firestore.DocumentData | undefined): Application {
  let smartDevice = null;
  if (smartDeviceData) {
    smartDevice = {
      Name: smartDeviceData.Name,
      Version: smartDeviceData.Version,
      DeviceType: smartDeviceData.DeviceType,
      Idiom: smartDeviceData.Idiom,
      TimeZone: smartDeviceData.TimeZone,
      LanguageCode: smartDeviceData.LanguageCode,
      CountryCode: smartDeviceData.CountryCode,
      IsLocationEnabled: smartDeviceData.IsLocationEnabled,
      BluetoothState: smartDeviceData.BluetoothState,
      DoseDevices: smartDeviceData.DoseDevices,
    };
  }
  return {
    Id: data.Id,
    Version: data.Version,
    VersionBuild: data.VersionBuild,
    InstallationDateTime: data.InstallationDateTime?.toMillis(),
    MyHeartBeat: data.MyHeartBeat?.toMillis(),
    SchemaVersion: data.SchemaVersion,
    PushNotificationsToken: data.PushNotificationsToken,
    MarketingSourceId: data.MarketingSourceId,
    BluetoothPermissionState: data.BluetoothPermissionState,
    LocationPermissionState: data.LocationPermissionState,
    NotificationSettings: data.NotificationSettings,
    SmartDeviceId: data.SmartDeviceId,
    SmartDevice: smartDevice,
  };
}

export function toDoseDeviceEventModel(document: firebase.firestore.DocumentSnapshot): DoseDeviceEvent {
  const data = document.data()!;
  return {
    Id: document.id,
    DoseDeviceId: data.DoseDeviceId,
    EventType: data.EventType,
    InternalClock: data.InternalClock,
    InternalDateTime: data.InternalDateTime?.toMillis(),
    EventTimeStamp: data.EventTimeStamp,
    EventDateTime: data.EventDateTime?.toMillis(),
    EventCounter: data.EventCounter,
  };
}

export function toUserModel(data: firebase.firestore.DocumentData, firebaseUser: FirebaseUser | null): User {
  const firstName = data.FirstName && data.FirstName !== "" ? data.FirstName : "Empty";
  const lastName = data.LastName && data.LastName !== "" ? data.LastName : "Empty";
  const authMethod = firebaseUser == null ? "Unknown" : firebaseUser.providerData && firebaseUser.providerData.length > 0 ? firebaseUser.providerData.map((x) => x.providerID).join(", ") : "Anonymous";
  const createdAt = firebaseUser?.metadata.creationTime ? localizeDateUTCString(firebaseUser.metadata.creationTime) : "Unknown";
  return {
    Id: data.UserId,
    Email: data.Email,
    Name: firstName + " " + lastName,
    ImageUrl: userDefaultIcon,
    SchemaVersion: data.SchemaVersion,
    Fields: {
      Id: data.UserId,
      Email: firebaseUser?.email ?? "Unknown",
      Authentication: authMethod,
      "Account created": createdAt,
      Gender: data.Gender ?? "Unknown",
      "First Name": firstName,
      "Last Name": lastName,
      Birthday: data.DateOfBirth ?? "Unknown",
      "Onboarding Passed": data.PassedOnboardings && data.PassedOnboardings.find((p: { Key: string }) => p.Key === "InitialOnboarding") != null ? "Yes" : "No",
      "Phone Number": data.Phone ?? "Unknown",
      "Schema Version": data.SchemaVersion,
    },
    IsBringOn: data.IsBringOn,
    BringSound: data.BringSound,
    ContiguousInterval: data.ContiguousInterval,
    NotificationSound: data.NotificationSound,
    CriticalNotificationVolume: data.CriticalNotificationVolume,
    DayStartTime: data.DayStartTime,
    EscalateNotificationsSound: data.EscalateNotificationsSound,
    LastHandledTimeZoneChange: data.LastHandledTimeZoneChange,
    HistorySelectedMedicationIds: data.HistorySelectedMedicationIds,
    FirebaseUser: firebaseUser,
  };
}

export function toCustomGroupModel(document: firebase.firestore.DocumentSnapshot): CustomGroup {
  const data = document.data()!;
  return {
    Id: document.id,
    Name: data.Name,
    Description: data.Description,
    AlgoliaKey: data.AlgoliaKey,
    Users: data.Users,
  };
}
