/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dotenv from "dotenv";
import type { GatsbyConfig } from "gatsby";
import path from "path";

dotenv.config({ path: `.env.${process.env.NODE_ENV}` });

// Get paths of Gatsby's required rules, which as of writing is located at:
// https://github.com/gatsbyjs/gatsby/tree/fbfe3f63dec23d279a27b54b4057dd611dce74bb/packages/
// gatsby/src/utils/eslint-rules
const gatsbyRequiredRules = path.join(process.cwd(), "node_modules", "gatsby", "dist", "utils", "eslint-rules");

const config: Config = {
  siteMetadata: {
    title: "Dose Medbox - Dashboard",
    description: "Dose Medbox Administration",
    be_url: "https://dashboard.dosemedbox.com",
    client_id: "2",
    client_secret: "N1TjJ0qlxtyWRJYMLBDN2aQIO2iEwgA4BGG10svo",
    firebase_config: {
      apiKey: process.env.GATSBY_FIREBASE_API_KEY!,
      authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN!,
      databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL!,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID!,
      storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET!,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER!,
      appId: process.env.GATSBY_FIREBASE_APP_ID!,
      measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID!,
    },
    firestore_configuration: process.env.GATSBY_FIRESTORE_CONFIGURATION!,
    firebase_use_emulator: process.env.GATSBY_FIREBASE_USE_EMULATOR!,
    firestore_emulator_host: process.env.GATSBY_FIRESTORE_EMULATOR_HOST,
    firestore_emulator_port: process.env.GATSBY_FIRESTORE_EMULATOR_PORT ? (process.env.GATSBY_FIRESTORE_EMULATOR_PORT as unknown as number) : undefined,
    firebase_auth_emulator_url: process.env.GATSBY_FIREBASE_AUTH_EMULATOR_URL,
    firebase_functions_host: process.env.GATSBY_FIREBASE_FUNCTIONS_EMULATOR_HOST,
    firebase_functions_port: process.env.GATSBY_FIREBASE_FUNCTIONS_EMULATOR_PORT ? (process.env.GATSBY_FIREBASE_FUNCTIONS_EMULATOR_PORT as unknown as number) : undefined,
    firebase_storage_host: process.env.GATSBY_FIREBASE_STORAGE_EMULATOR_HOST,
    firebase_storage_port: process.env.GATSBY_FIREBASE_STORAGE_EMULATOR_PORT ? (process.env.GATSBY_FIREBASE_STORAGE_EMULATOR_PORT as unknown as number) : undefined,
    algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
    algoliaApiKey: process.env.GATSBY_ALGOLIA_API_KEY,
  },
  /* Your site config here */
  plugins: [
    // it makes redirect from /* to index.js
    `gatsby-plugin-netlify`,
    `gatsby-plugin-postcss`,
    {
      resolve: "@sentry/gatsby",
      options: {
        dsn: "https://3fce35239357436fb607af0a0ed940c2@o1050071.ingest.sentry.io/6031339",
        sampleRate: 0.7,
      },
    },
    {
      resolve: "gatsby-plugin-eslint",
      options: {
        // Gatsby required rules directory
        rulePaths: [gatsbyRequiredRules],
        // Default settings that may be ommitted or customized
        stages: ["develop"],
        extensions: ["js", "jsx", "ts", "tsx"],
        exclude: ["node_modules", ".cache", "public"],
        // Any additional eslint-webpack-plugin options below
        // ...
      },
    },
  ],
};

export type Config = GatsbyConfig & {
  siteMetadata: {
    firebase_config: {
      apiKey: string;
      authDomain: string;
      databaseURL: string;
      projectId: string;
      storageBucket: string;
      messagingSenderId: string;
      appId: string;
      measurementId: string;
    };
    firestore_configuration: string;
    firebase_use_emulator: string;
    firestore_emulator_host: string | undefined;
    firestore_emulator_port: number | undefined;
    firebase_auth_emulator_url: string | undefined;
    firebase_functions_host: string | undefined;
    firebase_functions_port: number | undefined;
    firebase_storage_host: string | undefined;
    firebase_storage_port: number | undefined;
    algoliaAppId: string | undefined;
    algoliaApiKey: string | undefined;
  };
};
export default config;
