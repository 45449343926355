import { createSlice, createSelector, createAsyncThunk, createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { getCanManageUsers, logout } from "./userDataSlice";
import firebase from "../firebase";
import { getCollection, toChatDashboardDataModel, toChats } from "../firebase/firestoreUtils";
import { Chat, ChatDashboardData } from "../firebase/models";
import type { RootState } from ".";

const chatsAdapter = createEntityAdapter<Chat>({
  selectId: (chat) => chat.Id,
  sortComparer: (a, b) => {
    const l = a.LastMessageTimestamp ?? 0;
    const r = b.LastMessageTimestamp ?? 0;
    if (l > r) {
      return -1;
    }
    if (l < r) {
      return 1;
    }
    return 0;
  },
});

const getInitialState: () => ChatsState = () => chatsAdapter.getInitialState();

export const chatsSlice = createSlice({
  name: "chats",
  initialState: getInitialState(),
  reducers: {
    chatsUpdated: chatsAdapter.upsertMany,
    dashboardChatDataLoaded: (state: ChatsState, action: { payload: ChatDashboardData }) => {
      state.dashboardChatData = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(logout.fulfilled, () => {
      return getInitialState();
    });
  },
});

export const { selectAll: getChats, selectById: getChatById } = chatsAdapter.getSelectors<RootState>((state) => state.chats);
export const getDashboardChatData = (state: RootState) => state.chats.dashboardChatData;
export const getChatByUserId: (state: RootState, arg0: string) => Chat | undefined = createSelector([getChats, (_state, userId) => userId], (chats, userId) => chats.find((chat) => chat.UserId === userId));

export const createChat = createAsyncThunk("chats/create", async (userId: string, { getState }) => {
  const existing = getChatByUserId(getState() as RootState, userId);
  if (existing != null) {
    return existing.Id;
  }
  const userSettingsDocs = await getCollection("user_settings").where("UserId", "==", userId).get();
  if (userSettingsDocs.docs.length !== 1) {
    throw Error("User is not found!");
  }

  const userSettings = userSettingsDocs.docs[0].data();
  const name = userSettings.FirstName ? userSettings.FirstName + " " + (userSettings.LastName ?? "") : "Anonymous";
  const userChatDocuments = await getCollection("chats").where("UserId", "==", userId).get();
  let chatDocument: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | null = userChatDocuments.docs.length > 0 ? userChatDocuments.docs[0].ref : null;
  if (chatDocument == null) {
    // create chat document
    chatDocument = getCollection("chats").doc(userId);
    await chatDocument.set({
      UserId: userId,
      UserName: name,
      SchemaVersion: userSettings.SchemaVersion,
      LastReadAt: firebase.firestore.FieldValue.serverTimestamp(),
      IsAnonymous: false,
    });
  }
  // update chat info document
  const chatInfo = { Id: chatDocument.id, UserId: userId, UserName: name };
  const chatsInfoDocument = getCollection("dashboard_chat_data").doc("chats_info");
  const chatsInfoSnapshot = await chatsInfoDocument.get();
  if (!chatsInfoSnapshot.exists) {
    // chatsInfoDocument.update will fail if the document doesn't exist
    await chatsInfoDocument.set({});
  }
  await chatsInfoDocument.update({ [`Chats.${chatDocument.id}`]: chatInfo });
  return chatDocument.id;
});

export const loadChatDashboardData = createAsyncThunk("chats/loadChatDashboardData", async (userId: string, { getState, dispatch }) => {
  if (getCanManageUsers(getState() as RootState)) {
    const chatDataDoc = getCollection("dashboard_chat_data").doc(userId);
    const chatData = await chatDataDoc.get();
    if (!chatData.exists) {
      await chatData.ref.set({ LastReadMessages: [] });
    }
    return chatDataDoc.onSnapshot({ includeMetadataChanges: false }, (snapshot) => dispatch(dashboardChatDataLoaded(toChatDashboardDataModel(snapshot))));
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return () => {};
});

export const loadChats = createAsyncThunk("chats/loadChats", async (_, { getState, dispatch }) => {
  if (getCanManageUsers(getState() as RootState)) {
    return getCollection("dashboard_chat_data")
      .doc("chats_info")
      .onSnapshot((documentSnapshot) => {
        dispatch(chatsUpdated(toChats(documentSnapshot)));
      });
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return () => {};
});

export default chatsSlice.reducer;

export const { chatsUpdated, dashboardChatDataLoaded } = chatsSlice.actions;
export type ChatsState = {
  dashboardChatData?: ChatDashboardData;
} & EntityState<Chat>;
