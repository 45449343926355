import { configureStore } from "@reduxjs/toolkit";
import customGroupsReducer from "./customGroupsSlice";
import chatsReducer from "./chatsSlice";
import userDataReducer from "./userDataSlice";
import userDataMiddleware from "./userDataMiddleware";
import settingsReducer from "./settingsSlice";
import firestoreApi from "./firestoreApi";

// devtools and thunk middlewares are added automatically
const store = configureStore({
  reducer: {
    customGroups: customGroupsReducer,
    chats: chatsReducer,
    userData: userDataReducer,
    settings: settingsReducer,
    [firestoreApi.reducerPath]: firestoreApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat([firestoreApi.middleware, userDataMiddleware]),
});

export default store;
export type RootStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
