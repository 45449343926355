import { createApi } from "@reduxjs/toolkit/query/react";
import { fakeBaseQuery } from "@reduxjs/toolkit/query";

const firestoreApi = createApi({
  reducerPath: "firestore",
  baseQuery: fakeBaseQuery(),
  endpoints: () => ({}),
  keepUnusedDataFor: 0,
});

export default firestoreApi;
