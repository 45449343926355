import React from "react";
import { Provider } from "react-redux";
import store from "./src/store";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import GatsbyConfig from "./gatsby-config";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "./src/pagesComponents/error";
import { navigate } from "gatsby";

const theme = createTheme({
  typography: {
    fontFamily: "Raleway",
  },
});

export default ({ element }: { element: React.ReactElement }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{GatsbyConfig.siteMetadata?.title as string}</title>
          </Helmet>
          <ErrorBoundary FallbackComponent={ErrorComponent} onReset={() => navigate(-1)}>
            {element}
          </ErrorBoundary>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
};
