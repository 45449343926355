import { createSlice, createAsyncThunk, createEntityAdapter, EntityState, createSelector } from "@reduxjs/toolkit";
import { getCanManageUsers, logout } from "./userDataSlice";
import { getCollection, toCustomGroupModel } from "../firebase/firestoreUtils";
import { CustomGroup } from "../firebase/models";
import type { RootState } from ".";

const FOCUS_GROUP_KEY = "FocusGroup";

const customGroupsAdapter = createEntityAdapter<CustomGroup>({
  selectId: (customGroup) => customGroup.Id,
  sortComparer: (a, b) => {
    return a.Name.localeCompare(b.Name);
  },
});

const getInitialState: () => CustomGroupsState = () => customGroupsAdapter.getInitialState();

export const customGroupsSlice = createSlice({
  name: "customGroups",
  initialState: getInitialState(),
  reducers: {
    customGroupsUpdated: customGroupsAdapter.upsertMany,
  },
  extraReducers(builder) {
    builder.addCase(logout.fulfilled, () => {
      return getInitialState();
    });
  },
});

export const { selectAll: getCustomGroups, selectById: getCustomGroupById } = customGroupsAdapter.getSelectors<RootState>((state) => state.customGroups);

export const getFocusGroup: (state: RootState) => CustomGroup | undefined = createSelector([getCustomGroups], (customGroups) => {
  return customGroups.find((g) => g.AlgoliaKey == FOCUS_GROUP_KEY);
});

export const getIsInFocusGroup: (state: RootState, userId: string) => boolean = createSelector([getFocusGroup, (_state, userId) => userId], (focusGroup, userId) => {
  if (!focusGroup) {
    return false;
  }
  return focusGroup.Users?.find((includedUser) => includedUser.UserId === userId) != null;
});

export const loadCustomGroups = createAsyncThunk("customGroups/loadCustomGroups", async (_, { getState, dispatch }) => {
  if (getCanManageUsers(getState() as RootState)) {
    return getCollection("dashboard_custom_groups").onSnapshot((documentsSnapshot) => {
      dispatch(customGroupsUpdated(documentsSnapshot.docs.map((x) => toCustomGroupModel(x))));
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return () => {};
});

export default customGroupsSlice.reducer;

export const { customGroupsUpdated } = customGroupsSlice.actions;
export type CustomGroupsState = EntityState<CustomGroup>;
