import firebase from "../firebase";
import { enUS } from "date-fns/esm/locale";
import { formatRelative } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";

export const DATE_FORMAT = "yyyy-MM-dd";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";
export const DATE_TIME_FORMAT_WITH_TIMEZONE = "yyyy-MM-dd HH:mmXXX";

const formatRelativeLocale = {
  lastWeek: `'Last' eeee 'at' ${TIME_FORMAT}`,
  yesterday: `'Yesterday at' ${TIME_FORMAT}`,
  today: `'Today at' ${TIME_FORMAT}`,
  tomorrow: `'Tomorrow at' ${TIME_FORMAT}`,
  nextWeek: `eeee 'at' ${TIME_FORMAT}`,
  other: DATE_TIME_FORMAT,
};

const locale = {
  ...enUS,
  formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token],
};

export function formatToRelative(date: Date) {
  return formatRelative(date, new Date(), { locale: locale });
}

export function compareDates(a: Date | number | firebase.firestore.Timestamp, b: Date | number | firebase.firestore.Timestamp) {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
}

export function localizeTimestamp(timestamp: firebase.firestore.Timestamp | number, timeZone: string | null | undefined) {
  const originalDate = toDate(timestamp);
  if (timeZone != null) {
    const zonedDate = utcToZonedTime(originalDate, timeZone);
    return format(zonedDate, DATE_TIME_FORMAT_WITH_TIMEZONE, { timeZone: timeZone });
  } else {
    const utcDate = utcToZonedTime(originalDate, "UTC");
    return format(utcDate, DATE_TIME_FORMAT, { timeZone: "UTC" }) + " UTC";
  }
}

export function localizeDateUTCString(utcString: string): string | null {
  const seconds = Date.parse(utcString);
  return format(seconds, DATE_TIME_FORMAT);
}

export function toDate(timestamp: firebase.firestore.Timestamp | number) {
  let originalDate;
  if (typeof timestamp == "number") {
    originalDate = firebase.firestore.Timestamp.fromMillis(timestamp).toDate();
  } else {
    originalDate = timestamp.toDate();
  }
  return originalDate;
}
