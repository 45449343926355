import { userAuthenticated, userInitialized, loadRolesAndPermissions, loadUserSettings, loadUnits, loadChartsData, loadDoseDeviceAlarmSettings } from "./userDataSlice";
import { loadChatDashboardData, loadChats } from "./chatsSlice";
import type { Middleware } from "@reduxjs/toolkit";
import type { AppDispatch } from ".";
import { loadCustomGroups } from "./customGroupsSlice";

let subscriptions: Array<() => void> = [];

const userDataMiddleware: Middleware = (store) => (next) => async (action) => {
  const { dispatch } = store;
  const appDispatch = dispatch as AppDispatch;
  if (userAuthenticated.match(action)) {
    dispatch(userInitialized(false));

    const user = action.payload;
    let isInitialized = false;
    if (user) {
      try {
        const userId = user.Id;
        // load user role and it's permissions
        await appDispatch(loadRolesAndPermissions(userId)).unwrap();

        // load user settings
        await appDispatch(loadUserSettings(userId)).unwrap();

        // here user is initialized
        appDispatch(userInitialized(true));
        isInitialized = true;

        // load and subscribe to chat dashboard data
        subscriptions.push(await appDispatch(loadChatDashboardData(userId)).unwrap());

        //load units for medications
        await appDispatch(loadUnits()).unwrap();

        //load settings for dose device alarms
        await appDispatch(loadDoseDeviceAlarmSettings()).unwrap();

        // load and subscribe to chats
        subscriptions.push(await appDispatch(loadChats()).unwrap());

        // custom groups
        subscriptions.push(await appDispatch(loadCustomGroups()).unwrap());

        // load data for charts
        await appDispatch(loadChartsData()).unwrap();
      } catch (error) {
        console.log(error);
        dispatch(userInitialized(isInitialized));
      }
    } else {
      subscriptions.forEach((cancellable) => cancellable());
      subscriptions = [];
    }
  }

  next(action);
};

export default userDataMiddleware;
