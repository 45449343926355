import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./userDataSlice";
import { RootState } from ".";

const getInitialState = () => ({
  activeUsersSearch: true,
  usersWithDoseDeviceSearch: false,
});

export const settingsSlice = createSlice({
  name: "settings",
  initialState: getInitialState(),
  reducers: {
    activeUsersSearchChanged: (state, action) => {
      state.activeUsersSearch = action.payload;
    },
    usersWithDoseDeviceSearchChanged: (state, action) => {
      state.usersWithDoseDeviceSearch = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(logout.fulfilled, () => {
      return getInitialState();
    });
  },
});

export default settingsSlice.reducer;

export const { activeUsersSearchChanged, usersWithDoseDeviceSearchChanged } = settingsSlice.actions;

export const getActiveUsersSearch = (state: RootState) => state.settings.activeUsersSearch;
export const getUsersWithDoseDeviceSearch = (state: RootState) => state.settings.usersWithDoseDeviceSearch;
